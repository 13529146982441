.ez__DashboardLayout {
  position: relative;

  .ez__ChargingActivityCard,
  .ez__TripCard {
    position: relative;

    .ez__PosBlock {
      @include TransForm(rotate(50deg));
    }

    .cardIcon {
      width: 60px;
      height: auto;
      svg {
        width: 50px;
        height: 50px;
      }
    }

    .ez__CardInfo {
      width: calc(100% - 90px);
      @include Media(767) {
        width: 100%;
      }
      .ez__InfoText {
        width: calc(100% - 40px);
      }
    }
  }

  .ez__HeaderSearch input {
    width: calc(100% - 30px);
    min-width: 300px;
    @include Media(1024) {
      min-width: 100%;
    }
  }

  .ez__LayoutDiv {
    position: relative;
    min-height: calc(100vh - 40px);
    z-index: -1;

    @media (min-width: 767px) {
      min-height: calc(100vh - 40px);
    }

    @media (min-width: 1024px) {
      min-height: calc(100vh - 65px);
    }

    .ez__Sidebar {
      position: absolute;
      width: 250px;
      left: 0;
      top: 0;
      height: calc(100vh - 40px);
      overflow-y: scroll;
      z-index: 9999;

      @media (min-width: 1024px) {
        height: calc(100vh - 65px);
      }

      @media (min-width: 1026px) {
        max-width: 300px;
        top: 0;
        left: 0;
        z-index: 99;
      }

      .ez__NavItems a.active {
        background: #ebffeb;
      }
    }

    .ez__LayoutPages {
      position: relative;
      width: 100%;
      height: calc(100vh - 40px);
      padding: 0px;
      overflow-y: scroll;

      @media (min-width: 1024px) {
        height: calc(100vh - 65px);
      }
    }

    .ez__Home iframe {
      width: 100%;
      height: 100%;
    }
  }
}

.ez__HeaderSearch * {
  color: #808080 !important;
  background: #ffffff !important;
  font-size: 1rem;
  input {
    background: transparent !important;
  }
}

.ez__HeaderSearch input,
.ez__FormInput input {
  position: relative;
  width: calc(100% - 30px);
}

.ez__FormInput .react-datepicker__tab-loop .react-datepicker-popper {
  z-index: 30;
}

//Add Vehicle
.ez__VehicleImage label {
  position: relative;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100%;
  height: 90%;
  border-color: #228b22;
  padding: 15px;
  svg {
    width: 60px;
    height: 60px;
    path {
      color: #228b22;
      fill: #228b22;
    }
  }
  .dmajdp {
    flex-grow: 0;
    margin-left: 10px;
    span:first-child {
      margin-bottom: 5px;
    }
  }
  min-width: -webkit-fill-available;
}

//Switch
.ezSwitch {
  position: relative;
  width: 60px;
}
.ezSwitch input[type="checkbox"] {
  display: none;
}
.ezSwitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #228b22;
  border-radius: 15px;
}
.ezSwitch-inner {
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.ezSwitch-inner:before,
.ezSwitch-inner:after {
  float: left;
  width: 50%;
  height: 20px;
  padding: 0;
  line-height: 20px;
  font-size: 8px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  box-sizing: border-box;
}
.ezSwitch-inner:before {
  content: "";
  padding-left: 5px;
  background-color: transparent;
  color: #ffffff;
}
.ezSwitch-inner:after {
  content: "";
  padding-right: 5px;
  background-color: transparent;
  color: #228b22;
  text-align: right;
}
.ezSwitch-switch {
  width: 13px;
  margin: 3.5px;
  background: #228b22;
  border: 1px solid #228b22;
  border-radius: 15px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 39px;
  transition: all 0.3s ease-in 0s;
}
.ezSwitch-cb:checked + .ezSwitch-label .ezSwitch-inner {
  margin-left: 0;
}
.ezSwitch-cb:checked + .ezSwitch-label .ezSwitch-switch {
  right: 0;
}

.tripMap {
  position: relative;
  @include Media(767) {
    height: 500px;
  }
}

//Review Card
.reviewFlexCard {
  position: relative;
  z-index: 1;
  &::before {
    content: "";
    position: absolute;
    top: 10px;
    left: 15px;
    width: 1px;
    height: 95%;
    z-index: -1;
    background: #d9d9d9;
  }
  .contentDiv {
    width: calc(100% - 50px);
  }
}

//Profile Drowpdown
.dropDownOverFlow {
  max-height: calc(100vh - 70px);
  height: 100%;
  overflow-y: scroll;
}

.scroll-shadows {
  z-index: 30;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  background:
    /* Shadow Cover TOP */ linear-gradient(
        white 30%,
        rgba(255, 255, 255, 0)
      )
      center top,
    /* Shadow Cover BOTTOM */ linear-gradient(rgba(255, 255, 255, 0), white 70%)
      center bottom,
    /* Shadow TOP */
      radial-gradient(
        farthest-side at 50% 0,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      center top,
    /* Shadow BOTTOM */
      radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.2),
        rgba(0, 0, 0, 0)
      )
      center bottom;

  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;
}

/* Not important to the functionality, just for this particular demo */
.scroll-shadows {
  list-style: none;
  padding-right: 0.5rem;
}
// .scroll-shadows > * {
//   padding: 0.2rem;
// }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.scroll-shadows {
  --scrollbarBG: transparent;
  --thumbBG: #677277;

  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.scroll-shadows::-webkit-scrollbar {
  width: 6px;
}
.scroll-shadows::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.scroll-shadows::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

#buttonDiv .S9gUrf-YoZ4jf .sM5MNb iframe {
  height: 64px !important;
  width: 395px !important;
}
