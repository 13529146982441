@tailwind base;
@tailwind components;
@tailwind utilities;

@import "variables";
@import "html";
@import "landing";
@import "auth";
@import "dashboard";
@import "landing";

@layer components {
  .dotted-line {
    border-top: 2px dotted #0ead0e; /* #10b981 is Tailwind's green-500 */
    width: 100%;
    height: 0; /* Make it a horizontal line */
    position: relative; /* Required for positioning the car icon */
  }

  .car-icon {
    position: absolute;
    top: -8px; /* Adjust based on the size of the car icon */
    left: 50%;
    transform: translateX(-50%);
    background-color: transparent; /* Optional: to give it a background */
    padding: 0 4px; /* Optional: to give it some padding */
  }
}

.mapOuterDiv > div {
  width: 100%;
  height: 100%;
}

.mapContainer {
  height: 100% !important;
}

// .ez__Header {
//   box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.11);
// }

.mapDirectionHeight {
  height: 400px;
}

.imageModalHeight {
  height: 500px;
  @include Media(575) {
    height: 300px;
  }
}

.lowerAlpha {
  list-style-type: lower-alpha;
}

//Loader
@keyframes ScaleInOut {
  0% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1.5);
  }
}

.transparency {
  background: rgba(0, 0, 0, 0.3);
}

.dot1 {
  width: 15px;
  height: 15px;
  animation: ScaleInOut 0.6s ease-in-out 0.2s infinite alternate;
}

.dot2 {
  width: 15px;
  height: 15px;
  animation: ScaleInOut 0.6s ease-in-out 0.4s infinite alternate;
}

.dot3 {
  width: 15px;
  height: 15px;
  animation: ScaleInOut 0.6s ease-in-out 0.6s infinite alternate;
}

//Checkboxes Style
input[type="radio"],
input[type="checkbox"] {
  accent-color: #228b22;
}

.PhoneInputInput {
  background-color: transparent;
}

//Select style
.headerFormSelect {
  .select__value-container {
    padding: 4px !important;
  }
}
.disableSelect {
  .select__control {
    border-color: #808080 !important;
  }
  .css-tj5bde-Svg {
    color: #808080 !important;
    fill: #808080 !important;
  }
}
.select__value-container {
  padding: 4px !important;
}

.css-v68sna-control,
.select__control,
.css-13cymwt-control {
  border-radius: 8px !important;
  border-color: #a8a8a8 !important;
}

.ez__Form {
  .select__value-container {
    padding: 6px !important;
    font-size: 14px !important;
  }

  .css-v68sna-control,
  .select__control,
  .css-13cymwt-control {
    border-radius: 8px !important;
    border-color: #d3d3d3 !important;
  }
}

.css-v68sna-control,
.select__control:hover,
.select__control:focus,
.css-13cymwt-control {
  border-color: #a8a8a8 !important;
  box-shadow: none !important;
}
.css-1u9des2-indicatorSeparator {
  background-color: #a1a1a1 !important;
}
.css-tj5bde-Svg {
  fill: #808080 !important;
  color: #808080 !important;
}

//Google Input
.css-2b097c-container {
  width: 90%;
  .css-1pahdxg-control,
  .css-1pahdxg-control:hover,
  .css-yk16xz-control {
    min-height: auto;
    border: 0 !important;
    box-shadow: none !important;
    .css-1hwfws3 {
      padding: 0;
    }
  }
  .css-1g6gooi {
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
  input,
  div,
  .css-1wa3eu0-placeholder,
  .css-1hwfws3 {
    width: 100%;
  }
  .css-1wy0on6 {
    display: none;
  }
}

//Input Type Number
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

table th,
table td {
  border: 1px solid #eee;
  padding: 10px;
}

// Scroll bar style
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #228b22;
  @include BorRadius(8px);
}

.ezComingSoonHeading {
  line-height: 6rem !important;
  background: linear-gradient(
    90deg,
    #228b22 3.3%,
    #84c547 34.16%,
    #35df8d 44.42%,
    #7880f7 63.23%,
    #2e49b8 98.56%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.PhoneInputCountrySelect {
  display: none;
}

.ez__AuthForm {
  position: relative;
  @include Media(1024) {
    width: 450px;
    margin: 0 auto;
  }
  @include Media(575) {
    width: 100%;
  }
}

.ezCard-width {
  min-width: 230px;
}

.ezSpace-between {
  justify-content: space-between;
}

.planSlider .slick-slider .slick-dots {
  bottom: -18px !important;
}

.planSlider .slick-slider .slick-dots li {
  margin: 0 !important;
}

.gm-style-iw-a .gm-style-iw-t {
  position: relative;

  .gm-style-iw-d {
    padding-top: 25px !important;
  }

  .gm-style-iw-chr {
    button {
      position: absolute !important;
      width: 32px !important;
      height: 32px !important;
      top: 0;
      right: 0;
      background: #fff;
      border: none;
      padding: 5px;
      outline: none;
      cursor: pointer;
      z-index: 1;

      span {
        margin: 0 !important;
      }

      :hover {
        outline: none;
      }
    }
  }

  .gm-style-iw-ch {
    display: none;
  }
}
