.ez__FormLine {
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 45%;
    left: 0;
    width: 42%;
    height: 2px;
    background: #8b8b8b;
  }
  &::after {
    content: "";
    position: absolute;
    top: 45%;
    right: 0;
    width: 42%;
    height: 2px;
    background: #8b8b8b;
  }
}
